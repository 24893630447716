<template>
  <q-card class="fit border">
    <q-card-section class="row items-center q-py-sm border-bottom">
      <slot name="header"/>

      <q-space/>

      <q-btn
          :color="color"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="$t('Filter')"
          no-caps
          unelevated
          @click="toggleFilter"
      />

      <q-btn
          color="orange"
          text-color="white"
          :label="`(${digitalMarks.length}) ${$t('Export to excel')}`"
          class="q-mr-sm"
          size="sm"
          no-caps
          unelevated
          @click="downloadExcel"
      />

      <slot/>
    </q-card-section>

    <filter-collapse
        :is-open="isOpen"
        :options="filterOptions"
        class="border-bottom q-py-sm"
        @submit="handleFiltersSubmit"
        @close="toggleFilter"
    />

    <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="digitalMarks"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="digitalMarksLoading"
        :filter="filter"
        :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="id" :props="props" class="text-subtitle1" auto-width>
            <strong>{{ props.row.id }}</strong>
          </q-td>

          <q-td key="order" :props="props" auto-width>
            <span v-if="props.row._embedded.order">{{ props.row._embedded.order.id }}</span>
          </q-td>

          <q-td key="orderProduct" :props="props" style="max-width: 25%;" auto-width>
            <span v-if="props.row._embedded.orderProduct">{{
                getOfferName(props.row._embedded.orderProduct)
              }}</span>
          </q-td>

          <q-td key="digitalMark" :props="props" style="max-width: 50%;" auto-width>
            {{ props.row.data }}
          </q-td>

          <q-td key="date" :props="props" auto-width>
            {{
              $moment(
                  props.row.created &&
                  typeof props.row.created === 'object'
                      ? props.row.created.date
                      : props.row.created
              ).format(appOptions.formats.date)
            }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </q-card>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex'

// Utils
import { buildQuery } from '../../../utils/query-utils'

// Components
import FilterCollapse from '../../filters/FilterCollapse.vue'

export default {
  name: 'DigitalMarksTable',
  emits: ['first-load'],
  components: {
    FilterCollapse
  },
  props: {
    flat: {
      type: Boolean,
      default () {
        return false
      }
    },
    account: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      filter: '',
      totalVolume: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 250,
        rowsNumber: 250
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Order Product'),
          name: 'orderProduct',
          align: 'left'
        },
        {
          label: this.$t('Digital mark'),
          name: 'digitalMark',
          align: 'left'
        },
        {
          label: this.$t('Date'),
          name: 'date',
          align: 'left',
          sortable: true
        },
      ],
      isOpen: true,
      statuses: [
        { id: 'new', title: 'New' },
        { id: 'confirmed', title: 'Confirmed' }
      ],
      activatedFields: [
        'id',],
      order: null,
      filters: []
    }
  },
  computed: {
    ...mapGetters([
      'totalDigitalMarksNumber',
      'digitalMarksLoading',
      'digitalMarks',
      'appOptions'
    ]),
    filterOptions () {
      const options = {
        defaultFilter: this.filters,
        fields: this.activatedFields,
        schema: this.externalSchema,
        values: {
          states: this.statuses
        },
        style: {
          noGroups: true,
          flat: true,
          allOnThird: true
        }
      }

      return options
    },
    externalSchema () {
      return [
        {
          type: 'input',
          label: this.$t('Order'),
          wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
          value: this.order,
          icon: 'search',
          onChange: (value) => {
            this.order = value
          }
        }
      ]
    },
    color () {
      return this.filters.filter(x => x.field !== 'account').length > 0
          ? 'light-blue-7'
          : 'dark'
    }
  },
  watch: {},
  mounted () {
    this.loadDefaultItems()
        .then(() => {
          this.$emit('first-load')
        })
  },
  methods: {
    ...mapActions([
      'loadDigitalMarks'
    ]),
    downloadExcel () {
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      if (this.order) {
        query.filter = [
          ...query.filter,
          { type: 'eq', field: 'entityType', value: this.$entities.Orderadmin_Products_Entity_Order },
          { type: 'eq', field: 'entity', value: this.order }
        ]
      }

      return this.$service.digitalMark.downloadAll(query)
    },
    handleFiltersSubmit (filter) {
      this.filters = filter.map(e => {
        if (e.field === 'created') {
          e.value = e.value.split('T')[0]
        }
        return e
      })

      if (this.order) {
        this.filters.push({
          type: 'eq',
          field: 'order',
          value: this.order,
        })
      }

      this.isOpen = false
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      const pagination = {
        per_page: 250,
        page: 1,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ]
      }

      return this.onRequest({ pagination })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      return this.loadDigitalMarks(query)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }
            this.totalVolume = 0
            items.forEach(e => {
              this.totalVolume += e.value
              return
            })

            return { items, totalPages, page, totalItems }
          })
    },
    getOfferName (orderProduct) {
      if (orderProduct.productOfferRaw && typeof orderProduct.productOfferRaw === 'string') {
        orderProduct.productOfferRaw = JSON.parse(orderProduct.productOfferRaw)
      }

      return orderProduct.productOfferRaw?.name
    },
    decodeDigitalMark (digitalMark) {
      return Buffer.from(digitalMark, 'base64')
    }
  }
}
</script>
