<template>
  <digital-marks-table
      ref="transactions"
      :account="account"
      flat
      @first-load="handleFirstLoad"
  >
    <q-btn
        v-if="isSupervisior || isAdministrator"
        size="sm"
        color="green-6"
        icon="add"
        unelevated
        @click="handleAddTransaction"
    />
  </digital-marks-table>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import DigitalMarksTable from '../../../components/integrations/digital-mark/DigitalMarksTable.vue'

// Utils
import { doCopy } from '../../../utils/copy-utils'

export default {
  name: 'DigitalMarks',
  components: {
    DigitalMarksTable,
  },
  props: {
    tab: {
      type: String,
      default () {
        return 'account'
      }
    },
    account: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isExpand: true
    }
  },
  computed: {
    ...mapGetters([
      'isSupervisior',
      'isAdministrator'
    ])
  },
  methods: {
    ...mapMutations([
      'upsertAccount'
    ]),
    copy (value) {
      doCopy(value)
    },
    handleAdd () {
      this.$refs.invoiceModal.open({
        account: this.account
      })
    },
    handleSubmit () {
      this.$refs.transactions?.loadDefaultItems()

      // Update balance
      this.$service.account.get(this.account.id).then(this.upsertAccount)
    },
    refreshAccount () {
      if (!this.$service.account) {
        return
      }

      this.$service.account.get(this.account.id).then(this.upsertAccount)
    },
    handleAddTransaction () {
      this.$refs.transactionModal.open({
        account: this.account,
        currency: this.account._embedded.rate,
        tax: (this.account._embedded.contractor && this.account._embedded.contractor.tax) || 0,
        state: 'confirmed'
      })
    },
    handleExpand () {
      if (this.account._embedded && this.account._embedded.rate) {
        this.isExpand = !this.isExpand
      }
    },
    handleFirstLoad () {
      if (!this.$refs.graphic) {
        return
      }

      this.$refs.graphic.load()
    }
  }
}
</script>
